document.addEventListener("DOMContentLoaded", function () {
    // Different themes will use one of two <a> elements within _EventDateStub.cshtml, we adjust accessibility accordingly
    $('.c-event-date-stub__event-wrapper a').each(function () {
        var $link = $(this);
        var isVisible = $link.css('display') !== 'none' && $link.height() != 0 && $link.width() != 0;
        if (isVisible) {
            var ariaLabel = $link.data('aria-label');
            $link.attr('aria-label', ariaLabel);
        } else {
            $link.removeAttr('aria-label');
        }
    });
});